import { ChakraProvider, Image } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import AmorphusGradient from "../images/background_amorphus_gradient.svg";
import RoadMap from "../components/roadmap/roadmap";
import Footer from "../components/shared/footer";

function PhantomConnectionPage() {
  return (
    <ChakraProvider>
      <Header />
      <Image
        src={AmorphusGradient}
        background="black"
        width="100%"
        display={{ base: "block", sm: "none" }}
        paddingTop="3.2rem"
      />
      <RoadMap />
      <Footer />
    </ChakraProvider>
  );
}

export default PhantomConnectionPage;
