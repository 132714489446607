import { Box, Divider, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import BackgroundAmorphusGradientDesktop from "../../images/background_amorphus_gradient_desktop_from_bottom.svg";
import TempRoadmapIcon from "../../images/temp_roadmap_icon.svg";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
  OpaliteTextTitle,
} from "../shared/opalite_text";

interface RoadMapProps {}

const RoadMap: React.FC<RoadMapProps> = (props) => {
  return (
    <Box
      paddingTop={{ base: "0rem", sm: "6rem" }}
      height="100%"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      backgroundRepeat="no-repeat"
      backgroundImage={{
        base: "unset",
        sm: BackgroundAmorphusGradientDesktop,
      }}
      backgroundSize="100%"
      backgroundPosition="0 3.2rem"
      backgroundAttachment="fixed"
    >
      <Box padding="2rem" width={{ base: "100%", sm: "600px" }}>
        <OpaliteTextTitle marginBottom="2rem">Roadmap</OpaliteTextTitle>
        <OpaliteTextStandard marginBottom="2rem">
          We’ve got exciting things coming down the pipeline! Ver. 1.0 is just
          the tip of the iceburg.
        </OpaliteTextStandard>
        <OpaliteTextStandard marginBottom="1rem">
          VER. 2.0: RELEASE DATE{" "}
        </OpaliteTextStandard>
        <OpaliteTextSubTitle marginBottom="1rem">
          Milestone Title
        </OpaliteTextSubTitle>
        <OpaliteTextStandard marginBottom="2rem">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <OpaliteTextStandard marginTop="2rem" marginBottom="1rem">
          VER. 3.0: RELEASE DATE{" "}
        </OpaliteTextStandard>
        <OpaliteTextSubTitle marginBottom="1rem">
          Milestone Title
        </OpaliteTextSubTitle>
        <OpaliteTextStandard marginBottom="2rem">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <OpaliteTextStandard marginTop="2rem" marginBottom="1rem">
          VER. 4.0: RELEASE DATE{" "}
        </OpaliteTextStandard>
        <OpaliteTextSubTitle marginBottom="1rem">
          Milestone Title
        </OpaliteTextSubTitle>
        <OpaliteTextStandard marginBottom="2rem">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
        <Divider borderWidth="1px" />
        <Box paddingBlock="1rem" display="flex" flexDirection="row">
          <Image marginRight="1rem" src={TempRoadmapIcon} />
          <OpaliteTextStandard>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            convallis.
          </OpaliteTextStandard>
        </Box>
      </Box>
    </Box>
  );
};

export default RoadMap;
